import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../../components/share/banner/bannerH4'
import BannerH6 from '../../components/share/banner/bannerH6'
import BrandBoxes from '../../components/share/brandBoxes'
import ContentWrapper from '../../components/share/contentWrapper'
import ImageBackground from '../../components/share/imageBackground'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import BrandBox from '../../components/caseStudies/brandBox'

import customMedia from '../../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LJHookerBox: file(relativePath: { eq: "caseStudies/box-lj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    belleBox: file(relativePath: { eq: "caseStudies/box-belle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theAgencyBox: file(relativePath: { eq: "caseStudies/box-agency.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    harrisBox: file(relativePath: { eq: "caseStudies/box-harris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cunninghamsBox: file(
      relativePath: { eq: "caseStudies/box-cunninghams.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    barryPlantBox: file(
      relativePath: { eq: "caseStudies/box-barryplant.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LJHookerIcon: file(relativePath: { eq: "brandLogos/ljHooker.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    belleIcon: file(relativePath: { eq: "brandLogos/belleProperty.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    theAgencyIcon: file(relativePath: { eq: "brandLogos/theAgency.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    harrisIcon: file(relativePath: { eq: "brandLogos/harris.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cunninghamsIcon: file(relativePath: { eq: "brandLogos/cunninghams.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    barryPlantIcon: file(relativePath: { eq: "brandLogos/barryPlant.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    testimonial: file(relativePath: { eq: "share/testimonial.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class CaseStudies extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '700px' }}
                skew
              >
                <ContentWrapper style={{ marginTop: 0 }}>
                  <HeaderTitle>
                    The industry is moving, <br />
                    <strong>now it’s your turn...</strong>
                  </HeaderTitle>

                  <HeaderBody>
                    In 3 years we’ve made a significant impact on the entire
                    Australian <strong>real estate industry,</strong> moving
                    over individual agents, small offices, boutique brands and{' '}
                    <strong>entire national groups.</strong> We’re not finished
                    and we’re using all our existing customers to build
                    something brilliant. A better future for all professional
                    agents and agencies.
                  </HeaderBody>

                  <HeaderLink>
                    Read a few of our customer success stories
                    <Icon icon={['fal', 'arrow-down']} />
                  </HeaderLink>
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper
                direction="row"
                style={{
                  flexWrap: 'wrap',
                  margin: '-200px auto 90px auto',
                }}
              >
                <BrandBox showContent={false} brandName="LJHooker" />
                <BrandBox
                  showContent={false}
                  brandName="BelleProperty"
                  iconStyle={{ width: '75px' }}
                />
                <BrandBox showContent={false} brandName="TheAgency" />
                <BrandBox showContent={false} brandName="Harris" />
                <BrandBox showContent={false} brandName="Cunninghams" />
                <BrandBox showContent={false} brandName="BarryPlant" />
              </ContentWrapper>

              <ContentWrapper>
                <BannerH4>
                  We’re <strong>building something together</strong> led by
                  innovative agencies, don’t get <strong>left behind,</strong>{' '}
                  we’re already powering <strong>your competition.</strong>
                </BannerH4>

                <BannerH6>
                  Input from the largest and smallest real estate businesses in
                  Australia is helping to build the most effective marketing and
                  operations platform for professional agents.
                </BannerH6>
              </ContentWrapper>

              <ContentWrapper>
                <BrandBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.testimonial.childImageSharp.fluid}
                  title="Need more specific information?"
                  descWidth="50%"
                  reverse
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          If you’d like to speak to someone as a reference. Just
                          let us know.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        We’ve got hundreds of raving fans, and a whole bunch who
                        would be happy to tell you more about their experience
                        with us as a company. If you’ve got any questions for
                        ourselves or another real estate customer of ours just
                        let us know and we’ll be happy to put you in touch.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default CaseStudies

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    max-width: 600px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 2;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    width: 450px;
  `};
`

const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  width: 100%;

  ${customMedia.greaterThan('desktop')`
    margin-bottom: 10px;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`
